html,
body {
  position: relative;
  height: 100%;
}

body {

  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.newsletter {
  background: url(/assets/img/bg/bgf-newsletter.png) bottom center no-repeat #F2F3F5;
  background-size: contain;
  padding: 90px 0;
}
.newsletter h4 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.newsletter p {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 25px;
}

.newsletter form input {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  margin: 0;
  border-radius: 30px;
  border: 2px solid #e6e6e6;
}

.newsletter form input[type="submit"] {
  width: 13%;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  height: 37px;
  border: none;
  background: #24aeb1;
  color: #fff;
}

.newsletter form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  margin: 0 auto;
}


/* Feature Wrap */
.feature-wrap .ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}

.feature-wrap {
  z-index: 99;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
}

.feature-wrap .ul .li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.feature-wrap .ul .li .i {
  font-family: 'FontAwesome';
  background: #41cdcf;
  border-radius: 50%;
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.feature-wrap .ul .li h4 {
  font-size: 15px;
  padding: 0 15px;
  line-height: 19px;
  font-weight: 600;
}
.feature-wrap .ul .li h4 span {
  float: left;
  font-size: 14px;
  font-weight: normal;
}
.feature-wrap .ul .li:nth-child(2) .i {background: #f54f9a;}
.feature-wrap .ul .li:nth-child(3) .i {background: #83c847;}
.feature-wrap .ul .li:nth-child(4) .i {background: #51acf6;}
.feature-wrap .ul .li:nth-child(5) .i {background: #ffb61b;}
/* Feature Wrap End */

.feature-wrap2{
  background-color: #10847E;
  border-radius: 5px;
}


.feature-wrap2 ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}
 
.feature-wrap2 ul li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.feature-wrap2 ul li i {
  font-family: 'FontAwesome';
  text-align:left;
  line-height: 40px;
  color: #fff;
}

.feature-wrap2 ul li {
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}
.feature-wrap2 ul li  {
  float: left;
  font-size: 14px;
  font-weight: normal;
}
.text{
  color: white;
  margin-left: 10px;
  margin-right: 30px;
}
.inner-wrap{
  padding-left: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
}


.feature-brand {
  padding-right: 80px;
  padding-top: 80px;
}

.feature-brand h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.section{
  padding: 100px;
}

.search_button{
border: none;
background-color: #fff;
color: #10847E;
}
.pincode{
  background-color: #fff;
  
}
input[type=search] {
  background-color: white;
  border: none;
}
.ml{
  margin-left: 7px;
}
.ml2{
  margin-left: 20px;
  
}
.svg-inline--fa{
  color: #10847E;
}
.ml3{
  margin-left: 10px;
}

.mr{
  margin-right: 30px;
}
.mr2{
  margin-right: 10px;
}
.search_button{
  font-size: medium;
  display: inline-flex;
  align-items: center; 
  margin-top:10px;
  text-align: center;
}
.order{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #10847E;
}
.order2{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 8px;
}
.order3{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.p{
  font-size: 12px;

}
.maindiv{
  background-color: white;
    border-radius: 8px;
    border: 1px solid #10847E;

  }
.maindiv2{
  background-color: white;
    border-radius: 8px;
    border: 1px solid #10847E;
    padding: 10px;
  }
.call{
  font-size: 15px;
  display: inline-flex;
  align-items: center; 
  text-align: center;
  background-color:#10847E ;
  padding: 10px;
  color: white;
  border-radius: 8px;
}
.search{
  text-align: center;
  display: inline-flex;
  align-items: center; 
}
.wrapper1{
  display: inline-flex;
  align-items: center; 
  color:#10847E ;
  text-align: center;
}